import React, { Component } from 'react';
import firebase from 'firebase';
import FileUploader from 'react-firebase-file-uploader/lib/CustomUploadButton';
import './EditContact.css';


class EditContact extends Component{
  constructor(){
    super()

    this.state={
      user: false,
      showModal: false,
      photos: [],
      file: [],
      filename: [],
      filetype: [],
    }

    this.showModal = this.showModal.bind(this)
    this.handleUploadState = this.handleUploadStart.bind(this)
    this.handleProgress = this.handleProgress.bind(this)
    this.handleUploadSuccess = this.handleUploadSuccess.bind(this)

    this.handleText=this.handleText.bind(this)
  }

  showModal = () => {
    if (this.state.showModal === false ) {
      this.setState({showModal: true});
    } else {
      this.setState({showModal: false});
    }
  }

  handleUploadStart = () => this.setState({isUploading: true, progress: 0});

  handleProgress = (progress) => this.setState({progress});

  handleUploadError = (error) => {
    this.setState({
      isUploading: false});
    console.error(error);
  }

  handleUploadSuccess = (filename) => {
    this.setState({
      progress: 100,
      isUploading: false,
    });

    firebase.storage().ref('images').child(filename).getDownloadURL().then(url => {
      let multiplePhotos = this.state.photos
      let nextPosition = this.state.slideshowLength

      let newPhoto = {
        siteLocation: 'homePage',
        siteFunction: 'slideshow',
        position: nextPosition,
        imageURL: url
      }

      multiplePhotos.push(newPhoto)
      this.setState({
        photos: multiplePhotos,
        slideshowLength: multiplePhotos.length
      })

      this.saveSlide(newPhoto);
    });


  };

  handleText(event){
    event.preventDefault()
    this.setState({
      [event.target.id]: event.target.value
    })
  }

  saveChanges = () => {
    const db = firebase.firestore()

    db.collection('contactPage').doc('content').update({
        firmName: this.state.firmNameTemp,
        address1: this.state.address1Temp,
        address2: this.state.address2Temp,
        phone: this.state.phoneTemp,
        fax: this.state.faxTemp,
        email: this.state.emailTemp,
        imageURL: this.state.imageURLTemp,
    })
    .then((docRef) => {
      // this.saveAndClose();
      // need to reload info on homepage
      this.showModal();
      console.log('successfully saved')
    })
    .catch(function(error) {
        console.error("Error adding document: ", error);
    })
  }

  componentDidMount() {
    const db = firebase.firestore();
    const auth = firebase.auth();
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user });
      } 
    });

    db.collection('contactPage').get().then( ( snapshot ) => {
      snapshot.forEach((doc) => {
        let data = doc.data()
        this.setState({
          firmName: data.firmName,
          firmNameTemp: data.firmName,
          address1: data.address1,
          address1Temp: data.address1,
          address2: data.address2,
          address2Temp: data.address2,
          phone: data.phone,
          phoneTemp: data.phone,
          fax: data.fax,
          faxTemp: data.fax,
          email: data.email,
          emailTemp: data.email,
          imageURL: data.imageURL,
          imageURLTemp: data.imageURL
        })
      })
    });
  }
  
  render(){
    return(
      <div className='editView'>
        {this.state.user ? 
          <div>
            {this.state.showModal ? 
              <section className="add">
                <h2> Edit Contact Page</h2>

                  <div>
                    <div className="textFields">
                      <div className="leftText">
                        <img src={this.state.imageURL} className="imagePreview"/>
                        <div className='delete-btn'>Replace</div>
                        {/* Turn this into photo upload button */}
                      </div>

                      <div className="rightTextEdit">
                        <p className="title">Firm Name:</p>
                        <input 
                          value={this.state.firmNameTemp}
                          placeholder="Firm Name" 
                          onChange={this.handleText} 
                          id="firmNameTemp"
                        />

                        <p className="title">Address 1:</p>
                        <input 
                          value={this.state.address1Temp}
                          placeholder="Address 1" 
                          onChange={this.handleText} 
                          id="address1Temp"
                        />

                        <p className="title">Address 2:</p>
                        <input 
                          value={this.state.address2Temp}
                          placeholder="Address 2" 
                          onChange={this.handleText} 
                          id="address2Temp"
                        />

                        <p className="title">Phone:</p>
                        <input 
                          value={this.state.phoneTemp}
                          placeholder="Phone" 
                          onChange={this.handleText} 
                          id="phoneTemp"
                        />
                        <p className="title">Fax:</p>
                        <input 
                          value={this.state.faxTemp}
                          placeholder="Fax" 
                          onChange={this.handleText} 
                          id="faxTemp"
                        />
                        <p className="title">Email:</p>
                        <input 
                          value={this.state.emailTemp}
                          placeholder="Email" 
                          onChange={this.handleText} 
                          id="emailTemp"
                        />
                      
                      </div>

                    </div>
                  </div>
                
                <div className="bottomButtons">
                  <button className='submit' onClick={this.showModal}>Cancel</button>

                  <button className='submit' onClick={this.saveChanges}>Save Changes</button>
                </div>
              </section>
            :
              <button 
                className='editView-btn' 
                onClick={this.showModal}
              >Edit Page
              </button>
            } 
          </div>
        : 
          <div></div>
        }
      </div>
    );
  }
}

export default EditContact;