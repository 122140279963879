import React, { Component } from 'react';
import firebase from 'firebase';
import FileUploader from 'react-firebase-file-uploader/lib/CustomUploadButton';
import './AddProject.css';
import Nav from '../Nav/Nav';


class AddProject extends Component{
    constructor(){
        super()

        this.state={
            file: [],
            filename: [],
            filetype: [],
            title: '',
            location: '',
            type: 'Residential',
            size: '',
            role: '',
            description: '',
            photos: [],

            avatar: '',
            isUploading: false,
            progress: 0,
            thumbnailURL: '',
            headerURL: '',
            multiplePhotos: [],

        }

        this.handleUploadState = this.handleUploadStart.bind(this)
        this.handleProgress = this.handleProgress.bind(this)
        this.handleUploadThumbnailSuccess = this.handleUploadThumbnailSuccess.bind(this)
        this.handleUploadHeaderSuccess = this.handleUploadHeaderSuccess.bind(this)
        this.handleUploadMultipleSuccess = this.handleUploadMultipleSuccess.bind(this)
 
        this.handleText=this.handleText.bind(this)
        this.handleType=this.handleType.bind(this)
        this.handleSubmit=this.handleSubmit.bind(this)
        this.logout=this.logout.bind(this)

    }


    handleUploadStart = () => this.setState({isUploading: true, progress: 0});

    handleProgress = (progress) => this.setState({progress});

    handleUploadError = (error) => {
      this.setState({
        isUploading: false});
      console.error(error);
    }

    handleUploadThumbnailSuccess = (filename) => {
      this.setState({
        avatar: filename, 
        progress: 100,
        isUploading: false});

      firebase.storage().ref('images').child(filename).getDownloadURL().then(url => this.setState({
        thumbnailURL: url,
      }));
    };

    handleUploadHeaderSuccess = (filename) => {
        this.setState({
          avatar: filename, 
          progress: 100,
          isUploading: false});
  
        firebase.storage().ref('images').child(filename).getDownloadURL().then(url => this.setState({
          headerURL: url,
        }));
      };

    handleUploadMultipleSuccess = (filename) => {
    this.setState({
        avatar: filename, 
        progress: 100,
        isUploading: false,
    });
    firebase.storage().ref('images').child(filename).getDownloadURL().then(url => {
        let multiplePhotos = this.state.multiplePhotos
        multiplePhotos.push(url)
        this.setState({
        multiplePhotos: multiplePhotos,
        })
    });
    };

    

    handleText(event){
        event.preventDefault()
        this.setState({
            [event.target.id]: event.target.value
        })
        
    }

    handleType(event){
        this.setState({
            type: event.target.value
        })
      }

    handleSubmit(event){
        const db = firebase.firestore()
        // event.preventDefault

        db.collection('projects').doc(this.state.title).set({
            title: this.state.title,
            location: this.state.location,
            type: this.state.type,
            size: this.state.size,
            role: this.state.role,
            description: this.state.description,
            thumbnailURL: this.state.thumbnailURL,
            headerURL: this.state.headerURL,
            multiplePhotos: this.state.multiplePhotos
        }).then(function(docRef) {
            console.log("Document written with ID: ", docRef.id);
        })
        .catch(function(error) {
            console.error("Error adding document: ", error);
        });
    }

    componentDidMount() {
        const auth = firebase.auth();
        auth.onAuthStateChanged((user) => {
          if (user) {
            this.setState({ user });
          } 
        });
    }

    logout() {
        const auth = firebase.auth();
        auth.signOut()
          .then(() => {
            this.setState({
              user: null
            });
          });
      }


  
    render(){
        const optionTypes = ['Residential', 'Commercial', 'On The Boards', 'Mixed Use']
        return(
            <div>
                <Nav/>
                <div className="pageAdd">Add Projects To Portfolio</div><br/> <br/>
                {this.state.user ? 
                <section className="add">
                <button onClick={this.logout} className="submitButton">Logout</button>
                    <div className="loadMultiple">
                        <div className="loadPhoto">
                            <div className="progressPrevSquare">
                                {this.state.isUploading &&
                                <p>Progress: {this.state.progress}</p>
                                }

                                {this.state.thumbnailURL &&
                                <img src={this.state.thumbnailURL} alt="Logo Preview"className="logoPrev" />
                                }
                            </div>
                            <FileUploader
                                accept="image/*"
                                name="avatar"
                                randomizeFilename
                                storageRef={firebase.storage().ref('images')}
                                onUploadStart={this.handleUploadStart}
                                onUploadError={this.handleUploadError}
                                onUploadSuccess={this.handleUploadThumbnailSuccess}
                                onProgress={this.handleProgress}
                                style={{ width: '150px', height: '50px', fontSize:'18px', backgroundColor: '#D50000', color: 'white', marginTop: '10px', marginLeft: '0px', padding: 15, borderRadius: 5, cursor: 'pointer'}}
                            >Upload Thumbnail (1Wx1H)
                            </FileUploader>
                        </div>

                        <div className="loadPhotoRight">
                            <div className="progressPrevHeader">
                                {this.state.isUploading &&
                                <p>Progress: {this.state.progress}</p>
                                }

                                {this.state.headerURL &&
                                <img src={this.state.headerURL} alt="Logo Preview"className="logoPrev" />
                                }
                            </div>
                            <FileUploader
                                accept="image/*"
                                name="avatar"
                                randomizeFilename
                                storageRef={firebase.storage().ref('images')}
                                onUploadStart={this.handleUploadStart}
                                onUploadError={this.handleUploadError}
                                onUploadSuccess={this.handleUploadHeaderSuccess}
                                onProgress={this.handleProgress}
                                style={{ width: '150px', height: '50px', fontSize:'18px', backgroundColor: '#D50000', color: 'white', marginTop: '10px', marginLeft: '0px', padding: 15, borderRadius: 5, cursor: 'pointer'}}
                            >Upload Header (7W x 5H)
                            </FileUploader>
                        </div>
                    </div>
                    <div className="loadMultiple">
                        <div className="loadMultiplePhoto">
                            <div className="progressPrevMultiple">
                                {(this.state.multiplePhotos).map((e,i)=>{
                                    return (
                                        <img src={e} alt="Logo Preview" key={i}className="logoPrevMultiple" />
                                    )
                                  })
                                }
                            </div>
                            <FileUploader
                                accept="image/*"
                                multiple
                                name="avatar"
                                randomizeFilename
                                storageRef={firebase.storage().ref('images')}
                                onUploadStart={this.handleUploadStart}
                                onUploadError={this.handleUploadError}
                                onUploadSuccess={this.handleUploadMultipleSuccess}
                                onProgress={this.handleProgress}
                                style={{ width: '150px', height: '50px', fontSize:'18px', backgroundColor: '#D50000', color: 'white', marginTop: '10px', marginLeft: '0px', padding: 15, borderRadius: 5, cursor: 'pointer'}}
                            >Upload Gallery Photo
                            </FileUploader>
                        </div>
                    </div>
                   
                    <div className="textFields">
                        <div className="leftText">
                            <input placeholder="Project Title" onChange={this.handleText} id="title"/>
                            <input placeholder="Location" onChange={this.handleText} id="location"/>
                            
                            <p className="title">Project Type:</p>
                            <select onChange={this.handleType} value={this.state.value} placeholder="Type" className="dropOptions">
                                {optionTypes.map((e,i)=>{
                                    return(
                                        <option key={i} value={e}>{e}</option>
                                    )
                                    })
                                }
                            </select>
                                
                            <input placeholder="Size" onChange={this.handleText} id="size"/>
                            <input placeholder="Project Role" onChange={this.handleText} id="role"/>
                        </div>
                        <div className="rightText">
                            <textarea placeholder="Paragraph Description" onChange={this.handleText} id="description"/>
                        </div>
                    </div>

                    <div className="bottomButtons">
                        <button className='submit' onClick={this.handleSubmit}>Submit Project</button>
                    </div>

                    
                </section> : <div> Please Login to Add Projects</div>

                            }

            </div>
        );
    }
}

export default AddProject;