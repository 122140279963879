import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Close from './close-button.png';
import Logo from './PA_Logo.jpg';
import './Nav.css'

class Nav extends Component{
    constructor(){
        super()
        this.state={
            open: "closed",
        }

        this.handleNav = this.handleNav.bind(this)
    }

    handleNav(){
        if (this.state.open === "open"){
            this.setState({
                open: 'closed'
            })
        } if (this.state.open === "closed"){
            this.setState({
                open: "open"
            })
        }
    }
    render(){
        return(
            <div className="nav">

            <div className="boundary">
                <Link to="/" className="company">
                    <div className="navIcon">
                        <img src={Logo} className="logo"/>
                    </div>
                    <div className="companyName">Piekarz Associates</div>
                </Link>
                <div className= "navLinks">
                    <Link to="/" className="link">Home</Link>
                    <Link to="/about" className="link">About</Link>
                    <Link to={{
                        pathname:'/projects'
                      }} className="link">Projects</Link>
                    <Link to="/contact" className="link">Contact</Link>
                </div>
{/* Need a hamburger menu icon, should i just use burger menu? */}
                <div className={`hamburger-`+this.state.open} onClick={this.handleNav} >
                    <div className="barOne"></div>
                    <div className="barTwo"></div>
                    <div className="barThree"></div>
                </div>

                <div className={`closebtn-`+this.state.open} onClick={this.handleNav}>
                      <img src={Close} className="ex"/>
                </div>


            </div>

                <div className={`mobileNav-`+this.state.open}>
                    <Link to="/" className="mobileList">Home</Link>
                    <Link to='/about' className="mobileList">About</Link>
                    <div  className="mobileListTwo">
                    <Link to="/projects" className="mobileList">Projects</Link>
                        <Link to={{
                            pathname:"/projects/Residential",
                            state: "Residential"
                        }} className="sublist" onClick={this.handleNav}>— Residential</Link>
                        <Link to={{
                            pathname:"/projects/Commercial",
                            state: "Commercial"
                        }} className="sublist" onClick={this.handleNav}>— Commercial</Link>
                        <Link to={{
                            pathname:"/projects/On%20The%20Boards",
                            state: "On The Boards"
                        }} className="sublist" onClick={this.handleNav}>— On The Boards</Link>
                    </div>                    
                    <Link to='/contact' className="mobileList">Contact</Link>
                </div>

            </div>


        );
    }
}

export default Nav;