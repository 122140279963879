import React, { Component } from 'react';
import firebase from 'firebase';
import {Link} from 'react-router-dom';
import './Login.css'
import Nav from '../Nav/Nav'

class Login extends Component{
    constructor(){
        super()
        
        this.state={
            email: "",
            password: "",
            error: false
        }

        this.loginUser = this.loginUser.bind(this)
        this.handleText = this.handleText.bind(this)
    }

    loginUser() {
        const email = this.state.email
        const pass = this.state.password
        const auth = firebase.auth();

        const {history} = this.props
        auth.signInWithEmailAndPassword(email, pass).then(()=>{
            history.push('/addproject')
        })
    }

    handleText(event){
        event.preventDefault()
        this.setState({
            [event.target.id]: event.target.value
        })
        
    }

    render(){
        return(
            <div>
                <Nav/>
                <div className="LoginBody">
                    <input placeholder="Username" className="login" id="email" onChange={this.handleText}/>
                    <input placeholder="Password" type="password" className="login" id="password" onChange={this.handleText}/>
                    <div className={"wrongLogin-"+this.state.error}>Wrong Email or Password</div>
                    <button  className="submitBtn" onClick={this.loginUser}>Submit</button>

                </div>

            </div>
        );
    }
}

export default Login;