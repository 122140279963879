import React, { Component } from 'react';
import './App.css';
import * as firebase from 'firebase';
import {HashRouter, Route, Switch} from 'react-router-dom';
import Home from './components/Home/Home';
import About from './components/About/About';
import Projects from './components/Projects/Projects';
import Contact from './components/Contact/Contact';
import Details from './components/Details/Details';
import AddProject from './components/AddProject/AddProject';
import Login from './components/Login/Login';



class App extends Component {
  render() {
    return (
      <div className="App" >
        <HashRouter >
          <Switch>

          <Route exact path='/' component={ Home }/>
          <Route path='/about' component={ About }/>
          <Route path='/projects' component={ Projects }/>
          <Route path='/projects/:id' component={ Projects }/>
          <Route exact path='/details/:id' component={ Details }/>
          <Route path='/contact' component={ Contact }/>
          <Route path='/addproject' component={AddProject}/>
          <Route path='/login' component={Login}/>

          </Switch>
        </HashRouter> 
        
      </div>
    );
  }
}

export default App;
