import React, { Component } from 'react'; 
import './About.css';
import firebase from 'firebase';
import Nav from '../Nav/Nav';
import EditAbout from '../EditAbout/EditAbout';

class About extends Component{
    constructor(){
        super()
        this.state={
          paragraph1: '',
          paragraph2: '',
          paragraph3: '',
          imageURL: []
        }
      }

    componentDidMount() {
    const db = firebase.firestore();
        const auth = firebase.auth();
        auth.onAuthStateChanged((user) => {
            if (user) {
            this.setState({ user });
            } 
        });

        db.collection('aboutPage').get().then( ( snapshot ) => {
            snapshot.forEach((doc) => {
            let data = doc.data()
            this.setState({
                paragraph1: data.paragraph1,
                paragraph2: data.paragraph2,
                paragraph3: data.paragraph3,
                imageURL: data.imageURL,
            })
            })
        });
    }
    render(){
        return(
            <div>
                <Nav/>
                <div className="pageAbout">About Piekarz Associates</div> 
                <div className="aboutPiekarz">
                    <div className="imageHalf">
                        <img src={this.state.imageURL} className="officeImage"/>
                    </div>

                    <div className="textHalf">
                        <div className='studioDescription'>{this.state.paragraph1}<br/><br/>
                        {this.state.paragraph2}<br/><br/>
                        {this.state.paragraph3}
                        </div>
                    </div>
                </div>

                    {/* <div className="team">
                        <div className="pageTitle">Team</div><br/>
                        <div className="person">
                            <div className="avatar"></div>
                            <div className="name">Team Members Name</div>
                        </div>
                    </div> */}
               <EditAbout></EditAbout>
            </div>
        );
    }
}

export default About;