import React from 'react';
import ReactDOM from 'react-dom';
import * as firebase from 'firebase';  
import './index.css';
import App from './App';
import { unregister } from './registerServiceWorker';


var config = {
    apiKey: "AIzaSyB8U4TZ6t_y2J9Wfi6UKym_LhU_cemwDY0",
    authDomain: "piekarz-13cd5.firebaseapp.com",
    databaseURL: "https://piekarz-13cd5.firebaseio.com",
    projectId: "piekarz-13cd5",
    storageBucket: "piekarz-13cd5.appspot.com",
    messagingSenderId: "204336498060"
  };
  firebase.initializeApp(config);

  const firestore = firebase.firestore();
  const settings = {timestampsInSnapshots: true};
  firestore.settings(settings);
  export const db = firebase.firestore();
  
ReactDOM.render(
        <App />, 
    document.getElementById('root'));
unregister();