import React, { Component } from 'react';
import firebase from 'firebase';
import './Projects.css';
import _ from "lodash";
import {Link} from 'react-router-dom';
import Nav from "../Nav/Nav"

class Projects extends Component{
    constructor(){
        super()
        this.state={
            allProjects: [],
            filteredProjects: [],
            prev: 0,
            next: 0
        }
        
        this.filterProjects = this.filterProjects.bind(this)
        this.allProjects = this.allProjects.bind(this)
    }

    filterProjects(e){
        let projects = this.state.allProjects
        let type = e
        let filtered = _.filter(projects, {'type': type} )

        if (type !== 'On The Boards'){
            let mixeduse = this.state.allProjects
            let mixed = _.filter(mixeduse, {'type': 'Mixed Use'})
            let projects = _.orderBy(mixed.concat(filtered), ['title'], ['asc'])
            this.setState({
                filteredProjects: projects.sort()
            })
        }else {
            this.setState({
                filteredProjects: filtered
            })

        }


    }

    allProjects(){
        this.setState({
            filteredProjects: this.state.allProjects
        })
    }

    componentDidMount(e){
        const db = firebase.firestore()
    
        db.collection('projects').get().then( ( snapshot ) => {
            let projects = []
            snapshot.forEach((doc) => {
            projects.push(doc.data());
            });
            this.setState({
            allProjects: projects,
            filteredProjects: projects
            });

            
            let type = this.props.history.location.state
            if(type !== undefined){
                let filtered = _.filter(projects, {'type': type} )
                if (type !== 'On The Boards'){

                    let mixeduse = this.state.allProjects
                    let mixed = _.filter(mixeduse, {'type': 'Mixed Use'})
                    let projects = _.orderBy(mixed.concat(filtered), ['title'], ['asc'])
                    this.setState({
                        filteredProjects: projects.sort()
                    })
                }else {
                    this.setState({
                        filteredProjects: filtered
                    })
        
                }

            }

        })

    };

    render(){

        return(
            <div>
                <Nav/>
                <div className="projectBody">
                    <div className="top">
                        <div className="pageTitle">Projects</div>
                        <div className="filterBtn">
                            <button className="filterDrop" onClick={this.allProjects} value="Residential">
                                <div className="textFilter">All</div>
                            </button>
                            <button className="filterDrop" onClick={(e)=>this.filterProjects('Residential')} value="Residential">
                                <div className="textFilter">Residential</div>
                            </button>
                            <button className="filterDrop" onClick={(e)=>this.filterProjects('Commercial')} value="On the Boards">
                                <div className="textFilter">Commercial</div>
                            </button>
                            <button className="filterDropTwo" onClick={(e)=>this.filterProjects('On The Boards')} value="On the Boards">
                                <div className="textFilter">On the Boards</div>
                            </button>
                        </div>
                    </div>

                    <div className="flexProj">
                        {(this.state.filteredProjects).map((e,i)=>{
                            let prev = 0
                            let next = 0
                            if (i === 0 ){
                                prev = this.state.filteredProjects.length - 1;
                                next = i += 1;
                            } else if (i === this.state.filteredProjects.length) {
                                prev = i -= 1;
                                next = 0;
                            } else {
                                prev = i -= 1;
                                next = i += 1;
                            }
                            return(
                                // ADD AN EDIT BUTTON TO REPLACE PHOTOS
                        <div key={i} className="placeholder">
                            <Link to={`/details/${e.title}`} className= "menuItem">
                            <div className="thumbnailContainer">
                                <img src={e.thumbnailURL} className="thumbnail" alt="product list"/>
                            </div>
                            <div className="basicInfo">
                                <div className="title">{e.title}</div>
                                <div className="type">{e.type}</div> 
                            </div>
                            </Link>
                        </div>
                            );
                        })};
                    </div>

                </div>

            </div>
        );
    }
}

export default Projects;