import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import firebase from 'firebase';
import Back from './back.png';
import Next from './next.png';
import './Home.css';
import Nav from '../Nav/Nav';
import EditViews from '../EditViews/EditViews'


class Home extends Component{
    constructor(){
        super()

        this.state = {
          total: 13,
          current: 0,
          photos: []
        }
        
        this.showNext = this.showNext.bind(this)
        this.showPrev= this.showPrev.bind(this)
    }

    showPrev(){
        const { total, current } = this.state
        this.setState({
            current: current - 1 === -1 ? total - 1 : current - 1  
        })
        clearInterval(this.intervalId)   
        this.intervalId = setInterval(this.autoNext, 5000)
    }

    showNext(){
        const { total, current } = this.state
        this.setState({
            current: current + 1 === total ? 0 : current + 1
        })
        clearInterval(this.intervalId)
        this.intervalId = setInterval(this.autoNext, 5000)
    }

    autoNext = () => {
        const { total, current } = this.state
        this.setState({
            current: current + 1 === total ? 0 : current + 1
        })
    }

    componentDidMount(){
      const db = firebase.firestore();
      const auth = firebase.auth();
      this.intervalId = setInterval(this.autoNext, 5000)

      auth.onAuthStateChanged((user) => {
        if (user) {
          this.setState({ user });
        } 
      });

      db.collection('photos')
        .where("siteLocation", "==", 'homePage' )
        .where("siteFunction", "==", 'slideshow' )
        .orderBy('position').get().then( ( snapshot ) => {
          let photos = []
          snapshot.forEach((doc) => {
              let photo = doc.data()
              let imageURL = photo.imageURL
              photos.push(imageURL);
          })
          let length = photos.length 
          this.setState({ 
            photos: photos,
            total: length
          })
      });

      db.collection('homePage').get().then( ( snapshot ) => {
        snapshot.forEach((doc) => {
          let data = doc.data()
          this.setState({
            mainBody: data.mainBody,
            address1: data.address1,
            address2: data.address2,
            address3: data.address3,
            phone: data.phone,
            lowerImageURL: data.lowerImageURL,
            middleHeader: data.middleHeader,
            lowerHeader: data.lowerHeader
          })
        })
      });
    }

    render(){

        return(
            <div>
                <Nav/>
                <div className="crop">
                <div className="navcontainer">
                    <img src={this.state.photos[this.state.current]} className="header" /><br/>
                    <img src={Back} onClick={this.showPrev}className="leftArrw"/>
                    <img src={Next} onClick={this.showNext}className="rightArrw"/>
                    </div>
                </div>

                <div className="welcome">

                    <div className="firmIntro">
                    <b>Piekarz Associates P.C.</b> {this.state.mainBody}
                    </div> 
                    <div className="sectionTitle">{this.state.middleHeader}</div>
                    <div className="projects">
                        <Link to={{
                            pathname:"/projects",
                            state: "Residential"
                        }}className="projType">
                            <img 
                            className="home-img"
                            src="https://firebasestorage.googleapis.com/v0/b/piekarz-13cd5.appspot.com/o/images%2F2a542d04-d5e7-4def-9022-b768f4bd2119.jpg?alt=media&token=d86c9103-afbe-4b96-b6ac-5760660bd0dd"/>
                            <h3>Residential</h3>
                        </Link>
                        <Link to={{
                            pathname:"/projects",
                            state: "Commercial"
                        }} className="projType">
                            <img 
                            className="home-img"
                            src="https://firebasestorage.googleapis.com/v0/b/piekarz-13cd5.appspot.com/o/images%2F3788ddd4-f7b1-4063-b268-1567967505bd.jpg?alt=media&token=89600acd-e447-42e0-9918-7dcec7537b74"/>
                            <h3>Commercial</h3>
                        </Link>
                        <Link to={{
                            pathname:"/projects",
                            state: "On The Boards"
                        }} className="projType">
                            <img 
                            className="home-img"
                            src="https://s3.us-east-2.amazonaws.com/piekarz-photos/Home+Page/park+view_Rhi_flat.jpg"/>
                            <h3>On The Boards</h3>
                        </Link>

                    </div>
                </div>
                <div className="bottomSection">
                    <div className="sectionTitle">{this.state.lowerHeader}</div>
                    <div className="contact">
                        <div className="contactText">
                        {this.state.address1} <br/>
                        {this.state.address2} <br/>
                        {this.state.address3} <br/>
                        {this.state.phone}
                        </div>
                        <div className="map">
                            <img src={this.state.lowerImageURL} className="mapImg"/>
                        </div>
                    </div>
                </div>
                { this.state.user ? 
                  <EditViews 
                    siteLocation='Home Page'
                    mainBody={this.state.mainBody}
                    middleHeader={this.state.middleHeader}
                    lowerHeader={this.state.lowerHeader}
                    address1={this.state.address1}
                    address2={this.state.address2}
                    address3={this.state.address3}
                    phone={this.state.phone}
                  ></EditViews>
                :
                  <div className="footer">
                      <Link to='/login' className="loginLink">Login</Link>
                  </div>
                }
            </div>

        );
    }
}

export default Home;