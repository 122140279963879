import React, { Component } from 'react';
import firebase from 'firebase';
import FileUploader from 'react-firebase-file-uploader/lib/CustomUploadButton';
import './EditAbout.css';


class EditAbout extends Component{
  constructor(){
    super()

    this.state={
      user: false,
      showModal: false,
      photos: [],
      file: [],
      filename: [],
      filetype: [],
    }

    this.showModal = this.showModal.bind(this)
    this.handleUploadState = this.handleUploadStart.bind(this)
    this.handleProgress = this.handleProgress.bind(this)
    this.handleUploadSuccess = this.handleUploadSuccess.bind(this)

    this.handleText=this.handleText.bind(this)
  }

  showModal = () => {
    if (this.state.showModal === false ) {
      this.setState({showModal: true});
    } else {
      this.setState({showModal: false});
    }
  }

  handleUploadStart = () => this.setState({isUploading: true, progress: 0});

  handleProgress = (progress) => this.setState({progress});

  handleUploadError = (error) => {
    this.setState({
      isUploading: false});
    console.error(error);
  }

  handleUploadSuccess = (filename) => {
    this.setState({
      progress: 100,
      isUploading: false,
    });

    firebase.storage().ref('images').child(filename).getDownloadURL().then(url => {
      let multiplePhotos = this.state.photos
      let nextPosition = this.state.slideshowLength

      let newPhoto = {
        siteLocation: 'homePage',
        siteFunction: 'slideshow',
        position: nextPosition,
        imageURL: url
      }

      multiplePhotos.push(newPhoto)
      this.setState({
        photos: multiplePhotos,
        slideshowLength: multiplePhotos.length
      })

      this.saveSlide(newPhoto);
    });


  };

  handleText(event){
    event.preventDefault()
    this.setState({
      [event.target.id]: event.target.value
    })
  }

  saveChanges = () => {
    const db = firebase.firestore()

    db.collection('aboutPage').doc('content').update({
        paragraph1: this.state.paragraph1Temp,
        paragraph2: this.state.paragraph2Temp,
        paragraph3: this.state.paragraph3Temp,
        imageURL: this.state.imageURLTemp,
    })
    .then((docRef) => {
      // this.saveAndClose();
      // need to reload info on homepage
      this.showModal();
      console.log('successfully saved')
    })
    .catch(function(error) {
        console.error("Error adding document: ", error);
    })
  }

  componentDidMount() {
    const db = firebase.firestore();
    const auth = firebase.auth();
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user });
      } 
    });

    db.collection('aboutPage').get().then( ( snapshot ) => {
      snapshot.forEach((doc) => {
        let data = doc.data()
        this.setState({
          paragraph1: data.paragraph1,
          paragraph1Temp: data.paragraph1,
          paragraph2: data.address2,
          paragraph2Temp: data.paragraph2,
          paragraph3: data.address3,
          paragraph3Temp: data.paragraph3,
          imageURL: data.imageURL,
          imageURLTemp: data.imageURL
        })
      })
    });
  }
  
  render(){
    return(
      <div className='editView'>
        {this.state.user ? 
          <div>
            {this.state.showModal ? 
              <section className="add">
                <h2> Edit About Page</h2>

                  <div>
                    <div className="textFields">
                      <div className="leftText">
                        <img src={this.state.imageURL} className="imagePreview"/>
                        <div className='delete-btn'>Replace</div>
                        {/* Turn this into photo upload button */}
                      </div>

                      <div className="rightTextEditAbout">
                        <p className="title">Paragraph 1:</p>
                        <textarea 
                          value={this.state.paragraph1Temp}
                          placeholder="Paragraph 1" 
                          onChange={this.handleText} 
                          id="paragraph1Temp"/>
                      

                        <p className="title">Paragraph 2:</p>
                        <textarea 
                          value={this.state.paragraph2Temp}
                          placeholder="Paragraph 2" 
                          onChange={this.handleText} 
                          id="paragraph2Temp"/>

                        <p className="title">Paragraph 3:</p>
                        <textarea 
                          value={this.state.paragraph3Temp}
                          placeholder="Paragraph 3" 
                          onChange={this.handleText} 
                          id="paragraph3Temp"/>
                      
                      </div>

                    </div>
                  </div>
                
                <div className="bottomButtons">
                  <button className='submit' onClick={this.showModal}>Cancel</button>

                  <button className='submit' onClick={this.saveChanges}>Save Changes</button>
                </div>
              </section>
            :
              <button 
                className='editView-btn' 
                onClick={this.showModal}
              >Edit Page
              </button>
            } 
          </div>
        : 
          <div></div>
        }
      </div>
    );
  }
}

export default EditAbout;