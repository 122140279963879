import React, { Component } from 'react';
import Nav from '../Nav/Nav';
import firebase from 'firebase';
import {Link} from 'react-router-dom';
import Back from './backoutline.png';
import Next from './nextoutline.png';
import Lightbox from 'react-images';
import './Details.css'

class Details extends Component{
  constructor(){
    super()
    this.state={
      title: "",
      titleTemp: "",
      location: "",
      locationTemp: "",
      type: "",
      typeTemp: "",
      size: "",
      sizeTemp: "",
      role: "",
      roleTemp: "",
      description: "",
      descriptionTemp: "",
      editModalStatus: "Closed",
      photos: [],
      current: 0,
      loggedIn: false,
      next: {
          title: "Next Project",
          location: "Location"
      },
      prev: {
          title: "Previous Projects",
          Location: "Location"
      },

      lightboxIsOpen: false,
      currentImage: 0,
    }

    this.handleText = this.handleText.bind(this);
    this.handleType = this.handleType.bind(this);
    this.saveAndClose = this.saveAndClose.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.showEditModal = this.showEditModal.bind(this)
    this.closeLightbox = this.closeLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
    this.gotoImage = this.gotoImage.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.showPrev = this.showPrev.bind(this);
    this.showNext= this.showNext.bind(this);

  }

  handleText(event){
    event.preventDefault()
    this.setState({
        [event.target.id]: event.target.value
    })
    
  }

  handleType(event){
    this.setState({
        value: event.target.value,
        typeTemp: event.target.value
    })
  }

  closeModal(){
    if (this.state.editModalStatus === "Open"){
      this.setState({
        editModalStatus: "Closed",
          titleTemp: this.state.title,
          locationTemp: this.state.location,
          value: this.state.type,
          typeTemp: this.state.type,
          sizeTemp: this.state.size,
          roleTemp: this.state.role,
          descriptionTemp: this.state.description,
      })
    } else {
      this.setState({
        editModalStatus: "Open"
      })
    }
  }

  saveAndClose() {
    this.setState({
      editModalStatus: "Closed",
      title: this.state.titleTemp,
      location: this.state.locationTemp,
      type: this.state.typeTemp,
      size: this.state.sizeTemp,
      role: this.state.roleTemp,
      description: this.state.descriptionTemp,
    })
  }

  saveChanges(){
    const db = firebase.firestore()

    db.collection('projects').doc(this.state.title).update({
        title: this.state.titleTemp,
        location: this.state.locationTemp,
        type: this.state.typeTemp,
        size: this.state.sizeTemp,
        role: this.state.roleTemp,
        description: this.state.descriptionTemp,
        // thumbnailURL: this.state.thumbnailURL,
        // headerURL: this.state.headerURL,
        // multiplePhotos: this.state.multiplePhotos
    })
    .then((docRef) => {
      this.saveAndClose();
    })
    .catch(function(error) {
        console.error("Error adding document: ", error);
    })
  }

  showEditModal(){
    if (this.state.editModalStatus === "Open"){
      this.setState({
        editModalStatus: "Closed"
      })
    } else {
      this.setState({
        editModalStatus: "Open"
      })
    }
  }

  showPrev(){
    const { current } = this.state
    this.setState({
      current: current - 1 === -1 ? (this.state.photos.length - 1) : current - 1  
    })
  }

  showNext(){
    const { current } = this.state
    this.setState({
        current: current + 1 === this.state.photos.length ? 0 : current + 1
    })
  }

  openLightbox (index, event) {
		event.preventDefault();
		this.setState({
			currentImage: index,
			lightboxIsOpen: true,
		});
	}
	closeLightbox () {
		this.setState({
			currentImage: 0,
			lightboxIsOpen: false,
		});
	}
	gotoPrevious () {
		this.setState({
			currentImage: this.state.currentImage - 1,
		});
	}
	gotoNext () {
		this.setState({
			currentImage: this.state.currentImage + 1,
		});
	}
	gotoImage (index) {
		this.setState({
			currentImage: index,
		});
    }
    
  componentWillReceiveProps(nextProps) {
    let projectName = nextProps.match.params.id.replace("%20", " ")
    if (projectName !== this.state.title) {
    const db = firebase.firestore()
    
    db.collection('projects').where("title", "==", projectName ).get().then( ( snapshot ) => {
        
      snapshot.forEach((doc) => {
        let project = doc.data()
        this.setState({
          title: project.title,
          titleTemp: project.title,
          location: project.location,
          locationTemp: project.location,
          type: project.type,
          typeTemp: project.type,
          size: project.size,
          sizeTemp: project.size,
          role: project.role,
          roleTemp: project.role,
          description: project.description,
          descriptionTemp: project.description,
          photos: project.multiplePhotos,
          header: project.headerURL
        })
      });
    })

    db.collection('projects').get().then( ( snapshot ) => {
      let projects = []
      let current = []
      snapshot.forEach((doc)=>{
      projects.push(doc.data())
      current.push(doc.data().title);
      })
      let index = current.indexOf(this.state.title)
      if (index === 0 ){
          
        this.setState({
          next: projects[index+1],
          prev: projects[projects.length-1]
        })
      } else if (index === projects.length-1){
        this.setState({
          next: projects[0],
          prev: projects[index-1]
        })
      }else {
        this.setState({
          next: projects[index+1],
          prev: projects[index-1]
        })
      }
    })       
    }
  }


  componentDidMount(){    
    let projectName = this.props.match.params.id.replace("%20", " ")
    const db = firebase.firestore()

    db.collection('projects').where("title", "==", projectName ).get().then( ( snapshot ) => {
        
      snapshot.forEach((doc) => {
        let project = doc.data()
        let allPhotos = project.multiplePhotos
        allPhotos.unshift(project.headerURL)

        this.setState({
          title: project.title,
          titleTemp: project.title,
          location: project.location,
          locationTemp: project.location,
          type: project.type,
          value: project.type,
          typeTemp: project.type,
          size: project.size,
          sizeTemp: project.size,
          role: project.role,
          roleTemp: project.role,
          description: project.description,
          descriptionTemp: project.description,
          photos: project.multiplePhotos,
          header: project.headerURL
        })
      });
      db.collection('projects').get().then( ( snapshot ) => {
        let projects = []
        let current = []
        snapshot.forEach((doc)=>{
        projects.push(doc.data())
        current.push(doc.data().title);
        })
        let index = current.indexOf(projectName)
        if (index === 0 ){
          this.setState({
            next: projects[index+1],
            prev: projects[projects.length-1]
          })
        } else if (index === projects.length-1){
          this.setState({
            next: projects[0],
            prev: projects[index-1]
          })
        }else {
          this.setState({
            next: projects[index+1],
            prev: projects[index-1]
          })
        }
      })
    })

    const auth = firebase.auth();
      auth.onAuthStateChanged((user) => {
        if (user) {
          console.log(user)
          this.setState({ loggedIn: true });
        }  else {
          this.setState({ loggedIn: false });
        }
      });
  };

  render(){
    const optionTypes = ['Residential', 'Commercial', 'On The Boards', 'Mixed Use']
      return(
        <div className="detailsPage">
          <Nav/>
          <div className="projectTitle">{this.state.title}</div>
          <div className="cropDetails">
            <div className="navContainer">
              <img src={this.state.photos[this.state.current]} className="headerDetails" />
              <img src={Back} onClick={this.showPrev}className="leftArrow"/>
              <img src={Next} onClick={this.showNext}className="rightArrow"/>
            </div>
          </div>

          <section className="information">
            <div className="overview">
              <strong>Location:</strong> {this.state.location} <br/>
              <strong>Type:</strong> {this.state.type} <br/>
              <strong>Size:</strong> {this.state.size} <br/>
              <strong>Role:</strong> {this.state.role}
            </div>

            <div className='description'>
              {this.state.description}
            </div>
            {this.state.loggedIn == true ? 
              <button className="edit" onClick={this.showEditModal} >Edit</button>
              : ""
            }
          </section>

          <section className={"editModal"+this.state.editModalStatus}>
            <div className="modalBody">
              <h1><strong>Edit {this.state.title}</strong></h1>
              <span className="closeModal" onClick={this.closeModal}><strong>X</strong></span> 

              <p className="title">Title:</p>
              <input type="text" value={this.state.titleTemp} onChange={this.handleText} id="titleTemp"/>

              <p className="title">Location:</p>
              <input value={this.state.locationTemp} onChange={this.handleText} id="locationTemp"/>

              <p className="title">Project Type:</p>
              <select onChange={this.handleType} value={this.state.value} placeholder="Type" className="dropOptions">
                  {optionTypes.map((e,i)=>{
                      return(
                          <option key={i} value={e}>{e}</option>
                      )
                    })
                  }
              </select>

              <p className="title">Size:</p>
              <input value={this.state.sizeTemp} onChange={this.handleText} id="sizeTemp"/>

              <p className="title">Project Role:</p>
              <input value={this.state.roleTemp} onChange={this.handleText} id="roleTemp"/>

              <p className="title">Description:</p>
              <textarea value={this.state.descriptionTemp} onChange={this.handleText} id="descriptionTemp"/>

              <div className="btn-group">
                  <button onClick={this.saveChanges}>Save</button>
                  <button onClick={this.closeModal}>Cancel</button>
              </div>
            </div>
          </section>

        <section className="photoGrid">
          <div className="flexImg">
            {(this.state.photos).map((e,i)=>{
              return(
              <button key={i} className="imgBtn" onClick={(e) => this.openLightbox(i, e)}>
                  <img src={e} className="picture" alt="project images"/>
              </button>
                )
              })}
          </div>
        </section>

        <Lightbox
          currentImage={this.state.currentImage}
          images={this.state.photos.map((e,i)=>{
                        return {src: e}
                    })}
          isOpen={this.state.lightboxIsOpen}
          onClickNext={this.gotoNext}
          onClickPrev={this.gotoPrevious}
          onClickThumbnail={this.gotoImage}
          onClose={this.closeLightbox}
          preventScroll={this.props.preventScroll}
          showThumbnails={true}
          // width='100'
        />
              
        <nav className="nav-slide">
          <Link to={`/details/${this.state.prev.title}`} className="prev">
            <span className="icon-wrap">
              <i className="material-icons">chevron_left</i>
            </span>
            <div>
              <h3>{this.state.prev.title}<span>{this.state.prev.location}</span></h3>
              <img src={this.state.prev.thumbnailURL} alt="Previous thumb"/>
            </div>
          </Link>

          <Link to={`/details/${this.state.next.title}`} className="next">
            <span className="icon-wrap">
              <i className="material-icons">chevron_right</i>
            </span>
            <div>
              <h3>{this.state.next.title}<span>{this.state.next.location}</span></h3>
              <img src={this.state.next.thumbnailURL} alt="Next thumb"/>
            </div>
          </Link>
        </nav>
      </div>
    );
  }
}

export default Details;