import React, { Component } from 'react';
import firebase from 'firebase';
import FileUploader from 'react-firebase-file-uploader/lib/CustomUploadButton';
import './EditViews.css';


class EditViews extends Component{
  constructor(){
    super()

    this.state={
      user: false,
      showModal: false,
      photos: [],
      file: [],
      filename: [],
      filetype: [],
      slideshowLength: 0
    }

    this.showModal = this.showModal.bind(this)
    this.handleUploadState = this.handleUploadStart.bind(this)
    this.handleProgress = this.handleProgress.bind(this)
    this.handleUploadSuccess = this.handleUploadSuccess.bind(this)

    this.handleText=this.handleText.bind(this)
  }

  showModal = () => {
    if (this.state.showModal === false ) {
      this.setState({showModal: true});
    } else {
      this.setState({showModal: false});
    }
  }

  toggleEdit = (value) => {
    if ( value === 'slideshow' ) {
      this.setState({showSlides: true});
    } else {
      this.setState({showSlides: false});
    }
  }

  handleUploadStart = () => this.setState({isUploading: true, progress: 0});

  handleProgress = (progress) => this.setState({progress});

  handleUploadError = (error) => {
    this.setState({
      isUploading: false});
    console.error(error);
  }

  saveSlide = (photoObject) => {
    const db = firebase.firestore()

    db.collection('photos').add({
        siteFunction: photoObject.siteFunction,
        siteLocation: photoObject.siteLocation,
        position: photoObject.position,
        imageURL: photoObject.imageURL,

    })
    .then((docRef) => {
      console.log('successfully saved image')
    })
    .catch(function(error) {
        console.error("Error adding document: ", error);
    })
    
  }

  handleUploadSuccess = (filename) => {
    this.setState({
      progress: 100,
      isUploading: false,
    });

    firebase.storage().ref('images').child(filename).getDownloadURL().then(url => {
      let multiplePhotos = this.state.photos
      let nextPosition = this.state.slideshowLength

      let newPhoto = {
        siteLocation: 'homePage',
        siteFunction: 'slideshow',
        position: nextPosition,
        imageURL: url
      }

      multiplePhotos.push(newPhoto)
      this.setState({
        photos: multiplePhotos,
        slideshowLength: multiplePhotos.length
      })

      this.saveSlide(newPhoto);
    });


  };

  handleText(event){
    event.preventDefault()
    this.setState({
      [event.target.id]: event.target.value
    })
  }

  saveChanges = () => {
    const db = firebase.firestore()

    db.collection('homePage').doc('content').update({
        mainBody: this.state.mainBodyTemp,
        middleHeader: this.state.middleHeaderTemp,
        lowerHeader: this.state.lowerHeaderTemp,
        lowerImageURL: this.state.lowerImageURLTemp,
        address1: this.state.address1Temp, 
        address2: this.state.address2Temp,
        address3: this.state.address3Temp,
        phone: this.state.phoneTemp
    })
    .then((docRef) => {
      // this.saveAndClose();
      // need to reload info on homepage
      this.showModal();
      console.log('successfully saved')
    })
    .catch(function(error) {
        console.error("Error adding document: ", error);
    })
  }

  componentDidMount() {
    const db = firebase.firestore();
    const auth = firebase.auth();
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user });
      } 
    });

    db.collection('photos')
      .where("siteLocation", "==", 'homePage' )
      .where("siteFunction", "==", 'slideshow' )
      .orderBy('position').get().then( ( snapshot ) => {
        let photos = []
        snapshot.forEach((doc) => {
            let photo = doc.data()
            photos.push(photo);
        })
        this.setState({ 
          photos: photos,
          slideshowLength: photos.length
        })
    });

    db.collection('homePage').get().then( ( snapshot ) => {
      snapshot.forEach((doc) => {
        let data = doc.data()
        this.setState({
          mainBody: data.mainBody,
          mainBodyTemp: data.mainBody,
          address1: data.address1,
          address1Temp: data.address1,
          address2: data.address2,
          address2Temp: data.address2,
          address3: data.address3,
          address3Temp: data.address3,
          phone: data.phone,
          phoneTemp: data.phone,
          lowerImageURL: data.lowerImageURL,
          lowerImageURLTemp: data.lowerImageURL,
          middleHeader: data.middleHeader,
          middleHeaderTemp: data.middleHeader,
          lowerHeader: data.lowerHeader,
          lowerHeaderTemp: data.lowerHeader
        })
      })
    });
  }
  
  render(){
    return(
      <div className='editView'>
        {this.state.user ? 
          <div>
            {this.state.showModal ? 
              <section className="add">
                <h2> Edit {this.props.siteLocation}</h2>
                <div className='editToggle'>
                  <div onClick={() => this.toggleEdit('details')}>Details</div>
                  <div onClick={() => this.toggleEdit('slideshow')}>Slideshow</div>
                </div>
                
                {this.state.showSlides ? 
                  <div className="editPhotos">

                  {/* need a 3 column grid for managing photos*/}
                  {/* pass photos in props, include id, on click to delete */}

                    {(this.state.photos).map((e,i)=>{
                      return(
                        <div key={i} className="placeholder">
                          <div className="thumbnailContainer addMargin">
                              <img src={e.imageURL} className="thumbnail" alt="product list"/>
                          </div>

                          <div className='delete-btn'>Remove</div>
                        </div>
                      )
                    })}

                    <FileUploader
                      accept="image/*"
                      multiple
                      name="avatar"
                      randomizeFilename
                      storageRef={firebase.storage().ref('images')}
                      onUploadStart={this.handleUploadStart}
                      onUploadError={this.handleUploadError}
                      onUploadSuccess={this.handleUploadSuccess}
                      onProgress={this.handleProgress}
                      style={{ width: '150px', height: '50px', fontSize:'18px', backgroundColor: '#D50000', color: 'white', marginTop: '10px', marginLeft: 'auto', marginRight: 'auto', padding: 15, borderRadius: 5, cursor: 'pointer'}}
                    >Upload Photos
                    </FileUploader>
                  </div>
                :
                  <div>
                    <div className="textFields">
                      <div className="leftText">
                      <p className="title">Company Description:</p>
                        <textarea 
                          value={this.state.mainBodyTemp}
                          placeholder="Company Description" 
                          onChange={this.handleText} 
                          id="mainBodyTemp"/>
                      </div>
 
                      <div className="rightTextEdit">
                        <p className="title">Mid-Page Header:</p>
                        <input  
                          value={this.state.middleHeaderTemp} 
                          placeholder="Mid-Page Header" 
                          onChange={this.handleText} 
                          id="middleHeaderTemp"
                        />

                        <p className="title">Lower Header:</p>
                        <input 
                          value={this.state.lowerHeaderTemp}
                          placeholder="Lower Header" 
                          onChange={this.handleText} 
                          id="lowerHeaderTemp"
                        />

                        <p className="title">Address Line 1:</p>
                        <input 
                          value={this.state.address1Temp}
                          placeholder="Address Line 1"
                          onChange={this.handleText} 
                          id="address1Temp"
                        />

                        <p className="title">Address Line 2:</p>
                        <input 
                        value={this.state.address2Temp}
                          placeholder="Address Line 2" 
                          onChange={this.handleText} 
                          id="address2Temp"
                        />
                        
                        <p className="title">Address Line 3:</p>
                        <input 
                          value={this.state.address3Temp}
                          placeholder="Address Line 3" 
                          onChange={this.handleText} 
                          id="address3Temp"
                        />

                        <p className="title">Phone:</p>
                        <input 
                         value={this.state.phoneTemp}
                          placeholder="Phone Number" 
                          onChange={this.handleText} 
                          id="phoneTemp"
                        />
                      </div>
                    </div>
                  </div>
                }
                <div className="bottomButtons">
                  <button className='submit' onClick={this.showModal}>Cancel</button>

                  <button className='submit' onClick={this.saveChanges}>Save Changes</button>
                </div>
              </section>
            :
              <button 
                className='editView-btn' 
                onClick={this.showModal}
              >Edit Page
              </button>
            } 
          </div>
        : 
          <div></div>
        }
      </div>
    );
  }
}

export default EditViews;