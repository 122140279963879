import React, { Component } from 'react';
import firebase from 'firebase';
import './Contact.css';
import Nav from "../Nav/Nav";
import EditContact from "../EditContact/EditContact";

class Contact extends Component{
    constructor(){
        super()
        this.state={
            firmName: '',
            address1: '',
            address2: '',
            phone: '',
            fax: '',
            email: '',
            imageURL: ''
        }
      }

    componentDidMount() {
        const db = firebase.firestore();
        const auth = firebase.auth();
        auth.onAuthStateChanged((user) => {
            if (user) {
            this.setState({ user });
            } 
        });

        db.collection('contactPage').get().then( ( snapshot ) => {
            snapshot.forEach((doc) => {
            let data = doc.data()
            this.setState({
                firmName: data.firmName,
                address1: data.address1,
                address2: data.address2,
                phone: data.phone,
                fax: data.fax,
                email: data.email,
                imageURL: data.imageURL
            })
            })
        });
    }
    render(){
        return(
            <div>
                <Nav/>
                <div className="pageContact">Contact Piekarz Associates</div>
                <div>
                    <div className="contact">
                        <div className="exterior">
                            <img src={this.state.imageURL} className="extImg"/>
                        </div>
                        <div className="contactInfo">
                        {this.state.firmName} <br/>
                        {this.state.address1} <br/>
                        {this.state.address2} <br/><br/>

                        phone: {this.state.phone} <br/>
                        fax: {this.state.fax} <br/><br/>

                        email: {this.state.email}
                        </div>
                    </div>
                </div>
                <EditContact></EditContact>
            </div>
        );
    }
}

export default Contact;